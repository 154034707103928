
let redirecturi = '';
if(window.location.hostname === 'localhost') {
  // Localhost
  redirecturi = 'http://localhost:3000';
}
else {
  // Production
  redirecturi = 'https://my.foodprint.eco/';
}
export const msalConfig = {
  auth: {
    clientId: 'e207f288-a006-44e1-8cc9-be4be0d2734d', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://foodprintfrance.b2clogin.com/foodprintfrance.onmicrosoft.com/B2C_1_signIn', // Choose SUSI as your default authority.
    knownAuthorities: ['foodprintfrance.b2clogin.com'], // Mark your B2C tenant's domain as trusted.
    redirectUri: redirecturi, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: redirecturi, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  }
}