import React, { useState } from 'react';
import { TextField, Button, Paper, Select, Typography } from '@mui/material';
import IngredientsList from './IngredientsList'; // Votre composant personnalisé
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { addrecipe } from '../../utils/utils';
import { useData, DataProvider } from '../../DataProvider';import { packaging_list } from './packaging.js';
import { Checkbox, ListItemText } from '@mui/material';

const getScoreColor = (score) => {
  if (score >= 80) {
    return 'green';
  } else if (score >= 50) {
    return 'orange';
  } else {
    return 'red';
  }
};

const RecipeForm = () => {
  const [title, setTitle] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [type, setType] = useState('maincourse');
  const [nbportion, setNbportion] = useState(0);
  const [packaging, setPackaging] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [recipeReady, setRecipeReady] = React.useState(false);
  const [recipe, setRecipe] = React.useState({'Nutriscore':'C', 'Ecoscore':'B', 'Footprint': 1050});
  const [processingError, setProcessingError] = React.useState([false, ''])
  const { data: recettesDATA, prod_stats,reports, restaurant, listRestaurants, client, isLoading, error, loadDataForRestaurant, accessToken } = useData();
  // champs obligatoires : title, nbportion, au moins un ingrédient, et la quantité de chaque ingrédient doit définie
  let ingredientsQuantityOK = ingredients.every(ingredient => ingredient.quantity !== '' && ingredient.quantity !== 0);
  let ingredientNameOK = ingredients.every(ingredient => ingredient.Name.length>0 );
  let inputRecipeOK = title === '' || nbportion === 0 || ingredients.length === 0 || !ingredientsQuantityOK || !ingredientNameOK;
  //use data
  const handleIngredientChange = (newIngredients) => {
        //convert quantity according to unit. Supported : l, kg, pce
        newIngredients = newIngredients.map(ingredient => {
          const ratio_unit = {"ml": 0.001, "cl": 0.01, "dl": 0.1, "l": 1, "g": 0.001, "kg": 1, "pce": 1}
          let quantity = ingredient.Quantity
          let unit = ingredient.Unit;
          //Si g ou kg, convertir en kg
          if(unit === "g"){
            quantity = quantity * ratio_unit[unit];
            unit = "kg";
          }
          //Si cl, dl ou ml, convertir en l
          if(unit === "cl" || unit === "dl" || unit === "ml"){
            quantity = quantity * ratio_unit[unit];
            unit = "l";
          }
          return {...ingredient, Quantity: quantity, Unit: unit};
        });
    setIngredients(newIngredients);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Validez et envoyez les données
    setLoading(true);
    let recipe = {
      "Name" : title,
      "client": restaurant,
      "Type" : type,
      "Nb_portion" : nbportion,
      "Packaging" : packaging,
      "Company" : "",
      "restaurant" : "",
      "Ingredients" : ingredients,
      "Status" : "valid"
    }
    addrecipe(restaurant, recipe,accessToken)    
    .then((response) => {
      if(!response){
        setProcessingError([true, "1"])
        setLoading(false);
      }if(response.error){
        //si already exists is in the error message
        if(response.error.includes("already exists")){
          setProcessingError([true, "2"])
          setLoading(false);

        }else{
          setProcessingError([true, "1"])
          setLoading(false);

        }
      }
      else{
        loadDataForRestaurant(restaurant,client);
        setProcessingError([false, ""])
        setRecipeReady(true);
        setRecipe(response);
        setLoading(false);
      }
    })
  };

  const handleUpdate = (key, value) => {
    // Mettez à jour les données du formulaire
    if (key === 'nbportion') {
      setNbportion(value);
    }
    if (key === 'type') {
      setType(value);
    }
    if (key === 'Packaging') {
      setPackaging(value);
    }
  }

  if (recipeReady) {
    var scoreColor = getScoreColor(parseInt(recipe['ecosinglescore']));
  }


  return (
    <Box>
      <Paper sx={{ maxWidth: '100%', overflowX: 'auto', padding: "5px", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
      <Typography variant="h6" style={{marginBottom:15}}>Informations générales sur la recette</Typography>
        <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', marginBottom:30, marginTop:10 }}>
        <div>
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          <Typography variant="h8" style={{marginBottom:5}}> Nom de la recette<span style={{color:"red"}}>*</span></Typography>
          <TextField 
            label="Nom de la recette" 
            value={title} 
            onChange={(e) => setTitle(e.target.value)} 
            fullWidth 
          />
        </div>
        
        <div style={{display: "flex", flexDirection: "column"}}>
          <Typography variant="h8" style={{marginBottom:5}}>Nombre de portions<span style={{color:"red"}}>*</span></Typography>
          <TextField
            type="number"
            value={nbportion}
            onChange={(e) =>{
              const value = parseInt(e.target.value, 10);
              if (value >= 0 || e.target.value === "") { // Accepter seulement les valeurs positives ou une entrée vide
                handleUpdate('nbportion', e.target.value);
              }
            }}
            inputProps={{ min: 0 }} // Utilisation de min pour bloquer les valeurs négatives
            fullWidth
          />
        </div>

        <div style={{display: "flex", flexDirection: "column"}}>
          <Typography variant="h8" style={{marginBottom:5}}>Type de la recette</Typography>
          <Select
            value={type}
            label="Type de recette"
            onChange={(e) => handleUpdate('type', e.target.value)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="starter">Entrée</MenuItem>
            <MenuItem value="maincourse">Plat</MenuItem>
            <MenuItem value="dessert">Dessert</MenuItem>
            <MenuItem value="condiment">Condiment</MenuItem>
            <MenuItem value="sauce">Sauce</MenuItem>
          </Select>

          
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          <Typography variant="h8" style={{marginBottom:5}}>Emballage</Typography>
          <Select
            label="Emballage"
            multiple
            value={packaging}
            onChange={(e) => handleUpdate('Packaging', e.target.value)}
            renderValue={(selected) => selected.join(',\n')}
          >
            {packaging_list.map((pack) => (
              <MenuItem key={pack} value={pack}>
                <Checkbox checked={packaging.includes(pack)} />
                <ListItemText primary={pack} />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <Typography variant="h6" className='ingredientTitle'>Liste des ingrédients</Typography>
      <IngredientsList ingredients={ingredients} onIngredientsChange={handleIngredientChange} />
      <div style={{textAlign:"center"}}>
      <Button disabled={inputRecipeOK || loading} type="submit" variant="contained" color="primary" style={{ marginTop: '20px'}}>
        Ajouter la recette           
        {loading && (
              <>
                <CircularProgress color='info'/>
              </>
            )}
      </Button>
      </div>

        </form>

      </Paper>
      <Box style={{ marginTop: 20, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
      {recipeReady && (
        <Alert severity="success" style={{ maxWidth: 600, display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom:16 }}>
          <AlertTitle>Analyse de la recette réussie !</AlertTitle>
          <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: 16 }}>Indicateurs écologiques</Typography>

          {/* Row for Ecological Score and Carbon Footprint */}
          <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 16, width: '100%', justifyContent: 'space-between' }}>
            
            {/* Carbon Footprint Box */}
            <Box style={{ backgroundColor: '#e0f7fa', padding: 16, borderRadius: 8, flex: '1 1 250px', whiteSpace: 'nowrap' }}>
              <Typography variant="h6">
                <strong>Empreinte carbone :</strong> {parseInt(recipe['Footprint'] / recipe['Nb_portion'])} gCO2eq / portion
              </Typography>
              <Typography variant="body2" style={{ color: '#616161', fontStyle: 'italic' }}>
                Équivaut à environ {parseInt((recipe['Footprint']) / recipe['Nb_portion'] / 85)} km en voiture🚗
              </Typography>
            </Box>

            {/* Ecological Score Box */}
            <Box style={{ backgroundColor: '#f0f4c3', padding: 16, borderRadius: 8, flex: '1 1 250px', whiteSpace: 'nowrap' }}>
              <Typography variant="h6">
                <strong>Score écologique :</strong>
                <span style={{ color: scoreColor, fontSize: '1.2em', marginLeft: 8 }}>{parseInt(recipe.ecosinglescore)}/100</span>
              </Typography>
            </Box>
          </Box>

          {/* Nutriscore and Ecoscore in secondary view */}
          <Box style={{ display: 'flex', gap: 16, justifyContent: 'center', alignItems: 'center', marginTop: 16 }}>
            <img
              src={`./nutriscore/${recipe.Nutriscore}.svg`}
              alt={`${recipe.Nutriscore}`}
              height="50"
              width="100"
              style={{ opacity: 0.8 }}
            />
            <img
              src={`./ecoscore/${recipe.Ecoscore}.svg`}
              alt={`${recipe.Ecoscore}`}
              height="50"
              width="50"
              style={{ opacity: 0.8 }}
            />
          </Box>
        </Alert>
      )}

      {processingError[0] && (
        <Alert severity="error" style={{ maxWidth: 600 }}>
          <AlertTitle>Erreur lors de l'analyse de la recette !</AlertTitle>
          Une erreur est survenue lors de l'analyse de la recette.<br />
          Réessayez, ou contactez le support si le problème persiste.
        </Alert>
      )}
    </Box>
    </Box>
    
  );
};

export default RecipeForm;
