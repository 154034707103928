import React from 'react';
import './myrecipes.css'; // Votre CSS pour le tableau de bord
import EnhancedTable from './TableRecipes';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Alert, AlertTitle } from '@mui/material';
import { useData } from '../../DataProvider';

const MyRecipes = () => {
  let navigate = useNavigate();
  const naviguateToAddRecipes = () => {
    navigate('/addrecipes'); // Remplacez par le chemin de votre autre page
  };

  const exportAll= () => {
    //TODO : LAunch azure function in order to generate report for all recipes
    //(in post body)
  };
  const { data, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error } = useData();
  if(isLoading){
    return(
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center' }} >
          <h1>Chargement des données</h1>
          <CircularProgress />
        </Box>
      </div>
    )
  }
  else if(error){
    /*return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center', verticalAlign: 'center' }} >
        <Alert severity="error" sx={{verticalAlign:'center'}}>
          <AlertTitle>Erreur</AlertTitle>
            Une erreur est survenue au chargement des données de votre restaurant. <br/>
            Rafraichissez la page, si cela ne fonctionne toujours pas, contactez le support (rubrique Aide & Contact).
        </Alert>
        </Box>
      </div>
    )*/
  }

  return (
    
    <div className="myrecipes">
    <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
      <img src="logo_bis.svg" alt="logo_bis" />
      <h1 style={{marginLeft:10}}>Mes recettes</h1>
    </Box>
    <EnhancedTable/>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
      }}>
      <Button 
        variant="contained" 
        style={{ backgroundColor: '#38A48E', color: '#FFFFFF', borderRadius:10, padding:10, margin:10, textAlign:"center" }}
        onClick={naviguateToAddRecipes}  
      >
        Ajouter une recette
      </Button>
      </Box>


    </div>
  );
};

export default MyRecipes;