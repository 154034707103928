import React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {formatDate} from '../../utils/utils.js';
import './menuentry.css';
import { ReactComponent as LogoAffichage } from '../../ecbar.svg'; // Importez votre SVG
import CircularProgress from '@mui/material/CircularProgress';
import DownloadButton from './DownloadButton.js';
import Box from '@mui/material/Box';
import { useData } from '../../DataProvider';
import { Alert, AlertTitle } from '@mui/material';
import CircularProgressWithLabel from "./CircularWithValueLabel.js"

const styles = {
  day: {
      fontSize: 14,
      fontWeight: 'bold',
   },
   title: {
      fontSize: 30,
      fontWeight: 'bold',
      textAlign: 'center', 
      color:'#175E8D'
   },
   input: {
      marginTop: 20,
      marginBottom: 20,
      justifyContent: 'center',
      width: "100%",     
    },
   groupeBoutons: {
      marginTop: 20,
      marginBottom: 20,
      justifyContent: 'center',
    },

  }

dayjs.locale('fr');

function SaisieMenuPage(){
  const [date, setDate] = React.useState(dayjs());
  const [recettes, setRecettes] = React.useState([{Name:""}]);
  const [menu, setMenu] = React.useState({});
  const [affichageOK, setAffichageOK] = React.useState(false);
  const [recettesOK, setRecettesOK] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { data: recettesDATA, prod_stats,reports, restaurant, listRestaurants, client, isLoading, error, loadDataForRestaurant, accessToken } = useData();
  const [nbPortions, setNbPortions] = React.useState({});
  const [numLines, setNumLines] = React.useState(1);
  const [urls, setUrls] = React.useState({});
  const [errorGeneration, setErrorGeneration] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [totalCO2, setTotalCO2] = React.useState(0);
  const [averageCO2, setAverageCO2] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (loading) {
        setProgress((prevProgress) => (prevProgress >= 95 ? 95 : prevProgress + 5));
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [progress, loading]);
    // Fonction pour calculer l'impact CO₂
  const calculateCO2Impact = () => {
    let total = 0;
    let count = 0;
      for (const index in menu) {
      const recette = menu[index];
      const portions = nbPortions[index] || 0;
        if (recette && recette.Footprint) {
        total += recette.Footprint/recette.Nb_portion * portions; 
        count += parseInt(portions);
      }
    }
      setTotalCO2(total/1000);
    setAverageCO2(count > 0 ? (total/1000 / count) : 0);
  };
  React.useEffect(() => {
    calculateCO2Impact();
  }, [nbPortions, menu]);

  // Fonction pour gérer la sélection d'une recette
  const handleSelectRecette = (event, value, index) => {
    if (value) {
      let paddingEdition = 1;
      if (menu[index]) {
        paddingEdition = 0;
      }
      setMenu({ ...menu, [index]: value });
      setRecettes([]);
      if (Object.keys(nbPortions).length === Object.keys(menu).length + paddingEdition) {
        setRecettesOK(true);
      } else {
        setRecettesOK(false);
      }
    }
  };
  // Fonction pour récupérer les recettes
  const handleFetchRecettes = async (newInputValue) => {
    setRecettes([]);
    if(newInputValue.length > 1){
      const recettesFiltrees = recettesDATA.filter((recette) => {
        return recette.Name.toLowerCase().includes(newInputValue.toLowerCase())
      })

      setRecettes(recettesFiltrees)
    }
  }

  const lancerGeneration = () => {
    setLoading(true);
    let recipes_id = ""
    for (const [key, value] of Object.entries(menu)) {
      recipes_id += value.id + ";"  
    }
    recipes_id = recipes_id.slice(0, -1)

    let portions = ""
    for (const [key, value] of Object.entries(nbPortions)) {
      portions += value + ";"
    }
    portions = portions.slice(0, -1)
    let formatDate = date.format('DD-MM-YYYY')
    fetch('https://foodprint-api.azure-api.net/foodprintFunctionsV3/http_trigger_displayGeneration?recipes_id='+recipes_id+'&portions='+portions+'&restaurant='+restaurant+"&date="+formatDate, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': "a9ab279af7544e16a0ee106909e1074f",
          'Authorization': `Bearer ${accessToken}`,
        }
      }).then(response => {
        if(!response){
          return "error"
        }else if(response.status === 200){
          return response.json()
        }else{
          return "error"
        }

      })
      .then(data => {
        if(data === "error"){
          setLoading(false)
          setErrorGeneration(true)
          return
        }else{
          //get download urls from response
          setUrls({"pdf": data.url_pdf, "jpeg":  data.url_jpeg})
          setLoading(false);
          setAffichageOK(true);
          setErrorGeneration(false)
          loadDataForRestaurant(restaurant, client)
          
        }
      })
  }
        
  const handleUpdateNbPortions = (index, value) => {
    let paddingEdition = 1
    if(nbPortions[index]){
      paddingEdition = 0
    }
    setNbPortions({...nbPortions, [index]:value})
    if(Object.keys(nbPortions).length +paddingEdition === Object.keys(menu).length){
      setRecettesOK(true)
    }else{
      setRecettesOK(false)
    }
  };
  const handleDeleteLine = (index) => {
    const newMenu = { ...menu };
    const newNbPortions = { ...nbPortions };
    
    // Supprime la recette et le nombre de portions correspondant
    delete newMenu[index];
    delete newNbPortions[index];

    setMenu(newMenu);
    setNbPortions(newNbPortions);
    setRecettesOK(false); // Recalcule l'état
};
  
  if(isLoading){
    return(
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center' }} >
          <h1>Chargement des données</h1>
          <CircularProgress />
        </Box>
      </div>
    )
  }
  else if(error){
    return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center', verticalAlign: 'center' }} >
        <Alert severity="error" sx={{verticalAlign:'center'}}>
          <AlertTitle>Erreur</AlertTitle>
            Une erreur est survenue au chargement des données de votre restaurant. <br/>
            Rafraichissez la page, si cela ne fonctionne toujours pas, contactez le support (rubrique Aide & Contact).
        </Alert>
        </Box>
      </div>
    )
  }
  // Créer les composants Autocomplete
  return(
    <div className='menuentry'>
    <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
      <img src="logo_bis.svg" alt="logo_bis" />
      <h1 style={{marginLeft:10}}>Saisie du menu</h1>
    </Box>

    <Stack spacing={2} direction="row" style={styles.input}>
      <div>
          <h1 style={styles.day}>Selectionner le jour à saisir</h1>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DateCalendar 
              value={date} 
              onChange={(newDate) => setDate(newDate)} 
              sx={{
                borderBottom: '1px solid #ccc', // Contours gris clair
                borderRight: '1px solid #ccc', // Contours gris clair
                borderLeft: '1px solid #ccc', // Contours gris clair
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Ombre légère
                borderRadius: '4px', // Bords arrondis
                fontFamily:"Alegreya Sans",
              }}
              />
        </LocalizationProvider>    
      </div>

      <div>
      <h1 style={styles.day}>Saisie du {formatDate(date)}</h1>
      <Stack spacing={2} direction="column">
      {[...Array(numLines)].map((_, index) => (
        <span key={index} style={{display:"flex", flexDirection: "line"}}>
          <Autocomplete
            key={index}
            options={recettes}
            getOptionLabel={(option) => option.Name}
            onChange={(event, value) => handleSelectRecette(event, value, index)}
            onInputChange={(event, newInputValue) => {handleFetchRecettes(newInputValue);}}
            isOptionEqualToValue={(option, value) => option.Name === value.Name}
            sx={{ width: 450 }}
            renderInput={(params) => <TextField {...params} label={`Recette ${index + 1}`} />}
          />
          <TextField
            label="Nombre de portions"
            type="number"
            value={nbPortions[index] || ''}
            onChange={(e) => handleUpdateNbPortions(index, e.target.value)}
            sx={{ width: 250 }}
          />
        </span>
      ))}
        <Button 
          variant="outlined"
          onClick={() => setNumLines(numLines + 1)}
          sx={{marginTop: 2}}
        >
          Ajouter une recette à afficher
        </Button>

      </Stack>
      </div>
    </Stack>
      
    <Stack spacing={2} direction="row" style={styles.groupeBoutons} disabled={loading}>
      <Button 
        variant="outlined" 
        color="success" 
        onClick={lancerGeneration}
        disabled={!recettesOK}
        sx={{ 
          width: 250, // Largeur du bouton
          height: 'auto', // Hauteur automatique pour s'adapter au contenu
          display: 'flex',
          flexDirection: 'column', // Empilement vertical
          alignItems: 'center', // Alignement au centre
          justifyContent: 'center',
          borderRadius:10
        }}
        >
          Valider le menu
          {loading && (
            <>
              <CircularProgressWithLabel value={progress} />
            </>
          )}
          <LogoAffichage 
          style={{ 
            width: '100%', 
            height: 'auto',
          }}       />
      </Button>
      <DownloadButton disabled={!affichageOK} dateFormat={date.format("DD-MM-YYYY")} urls={urls}/>
      {/*bouton pour aller à la page info du jour*/}

    </Stack>
    {
        errorGeneration && ( 
          <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center', verticalAlign: 'center' }} >
          <Alert severity="error" sx={{verticalAlign:'center'}}>
            <AlertTitle>Erreur lors de la validation du menu</AlertTitle>
              Une erreur est survenue à la validation du menu. <br/>
              Veuillez essayer de nouveau ou contactez le support (rubrique Aide & Contact).
          </Alert>
          </Box>
        )
    }
    {
        affichageOK && ( 
          <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center', verticalAlign: 'center' }} >
          <Alert severity="success" sx={{verticalAlign:'center'}}>
            <AlertTitle>Menu ajouté et affichage prêt</AlertTitle>
              Le menu a bien été pris en compte. <br/>
              Vous pouvez télécharger l'affichage via le bouton 'Télécharger' au format souhaité.
          </Alert>
          </Box>
        )
    }
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center', verticalAlign: 'center' }} >
          <Alert severity="info" sx={{verticalAlign:'center', marginTop:5}}>
            <AlertTitle>Impact CO₂</AlertTitle>
            <p>Total CO₂ produit pour ce menu: {totalCO2.toFixed(2)} kg</p>
            <p>Moyenne CO₂ par portion: {averageCO2.toFixed(2)} kg</p>
          </Alert>
        </Box>

    </div>


     )

}

export default SaisieMenuPage;
