import React from "react";
import "./LoginPage.css"; // Importer le fichier CSS pour le style
import Button from '@mui/material/Button';
import "@fontsource/josefin-sans"; // Defaults to weight 400
import { useMsal } from '@azure/msal-react';
import { useState } from "react";
import Pricing from "../Pricing/Pricing";

let redirecturi = '';
if(window.location.hostname === 'localhost') {
  // Localhost
  redirecturi = 'http://localhost:3000';
}
else {
  // Production
  redirecturi = 'https://my.foodprint.eco/';
}

function goToCalendy() {
    // Obtenir la date au format {year}-{month}
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}`;
    const urlDemo = `https://calendly.com/elisa-jourde-foodprint/30min?month=${formattedDate}`;
    window.open(urlDemo, "_blank");
}

const LoginPage = () => {
  const { instance } = useMsal();
  const [idToken, setIdToken] = useState("");
  const [SignUpMode, setSignUpMode] = useState(false);

  async function handleRedirect() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const codeVerifier = sessionStorage.getItem("code_verifier");

    const tokenUrl = `https://foodprintfrance.b2clogin.com/foodprintfrance.onmicrosoft.com/oauth2/v2.0/token`;
    const body = new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: 'e207f288-a006-44e1-8cc9-be4be0d2734d',
        code,
        redirect_uri: redirecturi,
        code_verifier: codeVerifier
    });

    const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: body.toString()
    });

    const tokenResponse = await response.json();
}

  const goToPasswordReset = async () => {
    const { codeVerifier, codeChallenge } = await generateCodeChallenge();
    sessionStorage.setItem("code_verifier", codeVerifier); // Stockez le code_verifier pour une utilisation ultérieure

    const passwordResetUrl = `https://foodprintfrance.b2clogin.com/foodprintfrance.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_password_reset` +
        `&client_id=e207f288-a006-44e1-8cc9-be4be0d2734d` +
        `&response_type=code` +
        `&redirect_uri=${encodeURIComponent(redirecturi)}` +
        `&scope=openid` +
        `&nonce=defaultNonce` + // Assurez-vous que le nonce est conforme à vos besoins
        `&prompt=login` + 
        `&code_challenge=${codeChallenge}` + 
        `&code_challenge_method=S256`; // Utilisez S256 comme méthode de challenge
    
    window.location.href = passwordResetUrl;
};
function base64UrlEncode(string) {
  return btoa(string) // Utilise btoa pour encoder en Base64
      .replace(/\+/g, '-') // Remplace '+' par '-'
      .replace(/\//g, '_') // Remplace '/' par '_'
      .replace(/=+$/, ''); // Supprime les caractères de remplissage '='
}

async function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return new Uint8Array(hash);
}
async function generateCodeChallenge() {
  const codeVerifier = Math.random().toString(36).substring(2); // Génère un code verifier aléatoire
  const hashed = await sha256(codeVerifier);
  const codeChallenge = base64UrlEncode(String.fromCharCode(...hashed)); // Convertit l'Uint8Array en chaîne pour l'encodage
  return { codeVerifier, codeChallenge };
}

  const Login = async () => {
      try {
          let {idToken} = await instance.loginPopup();
          setIdToken(idToken);
      } catch (error) {      
          error = error.errorMessage;
          // Vérifiez si l'erreur correspond à une réinitialisation de mot de passe
          if (error.includes("AADB2C90118")) {
            goToPasswordReset();
          }
      }
  }

  return (
    <div >
      {
        SignUpMode === true ? <Pricing /> : (
          <div className="container">
            <div className="left-half">
                <img src="illustration_login.png" alt="illustration_login.png"/>
            </div>
            <div className="right-half">
                <img src="logo_plateforme-cropped.svg" alt="logo foodprint" className="logo" />
                <div className="centered-rectangle">
                  <div className="rectangle-content">
                    <h1 className="welcome-text">Envie d'accèder à la plateforme ?</h1>

                    <Button
                        variant="contained" 
                        style={{ backgroundColor: '#37A18D', color: '#FFFFFF', borderRadius:20, fontFamily: "josefin sans"}}      
                        title="Sign In"
                        onClick={() => Login()}
                    >
                        Connexion
                    </Button>

                    <h1>Besoin d'en savoir plus ?</h1>
                    <Button onClick={goToCalendy} variant="contained" style={{ backgroundColor: '#37A18D', color: '#FFFFFF', borderRadius: 20,  fontFamily: "josefin sans" }}>Reserver une démonstration</Button>
                  </div>
                </div>
            </div>
          </div>
        )
      }
    </div>
  );
};


export default LoginPage;
