import React from 'react';
import './Pricing.css';

const Pricing = () => {
  const selectPlan = (e) => {
    // Stocker le plan choisi en storage local react
    localStorage.setItem('plan', e.target.value);
  }

  return (
    <div className="pricing-container">
      {/* Logo Foodprint centré */}
      <div className="logo-container">
        <img src="./logo_plateforme-cropped.svg" alt="Foodprint Logo" className="foodprint-logo" height={"150%"} />
      </div>
      
      <h2>Choisissez ce qui vous correspond le mieux</h2>
      
      <div className="pricing-comparison">
        <table className="pricing-table">
          <thead>
            <tr>
              <th>Fonctionnalités</th>
              <th>Basic</th>
              <th>Standard</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Prix</td>
              <td>79 €/mois</td>
              <td>490 €/mois</td>
              <td>490 €/mois/restaurant</td>
            </tr>
            <tr>
              <td>Utilisateurs</td>
              <td>1</td>
              <td>10</td>
              <td>10</td>
            </tr>
            <tr>
              <td>Analyses de recette/mois</td>
              <td>100</td>
              <td>5000</td>
              <td>20 000</td>
            </tr>
            <tr>
              <td>Affichage environnemental journalier</td>
              <td><span className="check">&#10003;</span></td>
              <td><span className="check">&#10003;</span></td>
              <td><span className="check">&#10003;</span></td>
            </tr>
            <tr>
              <td>Suivi de production</td>
              <td><span className="check">&#10003;</span></td>
              <td><span className="check">&#10003;</span></td>
              <td><span className="check">&#10003;</span></td>
            </tr>
            <tr>
              <td>Ressources pédagogiques</td>
              <td><span className="check">&#10003;</span></td>
              <td><span className="check">&#10003;</span></td>
              <td><span className="check">&#10003;</span></td>
            </tr>
            <tr>
              <td>Support client</td>
              <td><span className="check">&#10003;</span></td>
              <td><span className="check">&#10003;</span></td>
              <td><span className="check">&#10003;</span></td>
            </tr>
            <tr className="button-row">
              <td></td>
              <td><button className="select-plan" value={"basic"} onClick={selectPlan}>Choisir Basic</button></td>
              <td><button className="select-plan" value={"standard"} onClick={selectPlan}>Choisir Standard</button></td>
              <td><button className="select-plan">Contactez-nous</button></td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Section Accès API en dessous */}
      <div className="api-offer">
        <h3>Vous voulez utiliser les services Foodprint dans votre logiciel ou système informatique ?</h3>
        <h4>Accès API</h4>
        <p className="price">300 €<span>/mois</span></p>
        <ul>
          <li>Intégrez les services FoodPrint à votre logiciel</li>
          <li>5000 analyses de recette/mois</li>
          <li>Support client</li>
        </ul>
        <button className="select-plan">Contactez-nous</button>
      </div>
    </div>
  );
};

export default Pricing;
